import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'

import router from './router'
import axios from 'axios'
import Vuex from 'vuex'
import store from './store'

import PageLoader from './components/common/pageLoader'
import ImageLoader from './components/common/imageLoader'
import ServerError from './components/common/500'
import Logo from './components/common/logo'
import SnackBar from './components/common/snackBar'
import VueElementLoading from 'vue-element-loading'
import VueGtag from "vue-gtag";


import OtpInput from "@bachdgvn/vue-otp-input";
Vue.component("v-otp-input", OtpInput);


import OwlCarousel from 'vue-owl-carousel'
Vue.component('OwlCarousel', OwlCarousel)

Vue.component('PageLoader', PageLoader)
Vue.component('ImageLoader', ImageLoader)
Vue.component('ServerError', ServerError)
Vue.component('Logo', Logo)
Vue.component('SnackBar', SnackBar)
Vue.component('VueElementLoading', VueElementLoading)
Vue.use(Vuex)

Vue.config.productionTip = false

Vue.use(VueGtag, {
  config: { id: "G-P665H77SCN" }
});


window.axios = require('axios')


// axios.defaults.baseURL = 'http://192.168.49.237:3500/';
// Vue.prototype.baseURL = "http://192.168.49.237:3500/"

axios.defaults.baseURL = 'https://api.leopardtechlabs.in/';
Vue.prototype.baseURL = "https://api.leopardtechlabs.in/"
Vue.prototype.mediaURL = "https://api.leopardtechlabs.in/wp/"
Vue.prototype.mediaURLold = "https://api.leopardtechlabs.in/u/"
// Vue.prototype.appColor="#FF6907"
new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')