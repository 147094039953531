<template>
  <v-app id="app">
      <!-- <AppHeader /> -->
      
      <router-view
        style="min-height: 80vh"
        :style="$vuetify.breakpoint.name=='xl'?'padding-top:39px':''"
        :key="$route.fullPath"
      />
      <!-- <AppFooter /> -->
    </v-app>
</template>

<script>
import "./assets/styles/appStyle.css";
import "./assets/styles/fonts.css";

export default {
  name: 'App',

};
</script>
