import Vue from "vue";
import Vuex from "vuex";
import router from "./router";
// import axios from "axios";

Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    isLoggedIn: !!localStorage.getItem("internToken"),
    userType: localStorage.getItem("userType"),
    userData: {},
    fullData: {},
    email: "",
    menus: [],
    initial: "",
    appLoading: false,
    exceptPos: {},
  },
  mutations: {
    loginUser(state, payload) {
      localStorage.setItem("internToken", payload);
      state.isLoggedIn = true; 

      // if (state.initial) {
      //   router.push(state.initial);
      // } else router.push("/");
      router.push("/InternshipDetails");
    },
    logoutUser(state) {
      state.userType = null;
      state.isLoggedIn = false;
      state.userData = {};
      state.initial = "";
      // localStorage.removeItem("userType");
      // localStorage.removeItem("userid");
      // localStorage.removeItem('departmentId')
      localStorage.removeItem("internToken");
      // localStorage.removeItem("token");
      router.push("/Internship/login"); 
    }, 
    userData(state, payload) {
      state.userData = payload;
      state.email = state.userData.email;
    }, 
  },
});
