import Vue from "vue";
import Router from "vue-router";
import store from "./store";
Vue.use(Router);
let router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      props: true,
      component: () => import("./layouts/website"),
      meta: {
        requiresAuth: false,
      },
      children: [
        {
          path: "",
          name: "appHome",
          meta: {
            requiresAuth: false,
          },
          component: () => import("./views/appHome"),
        },
        {
          path: "Products",
          meta: {
            requiresAuth: false,
          },
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "Products",
              meta: {
                requiresAuth: false,
              },
              component: () => import("./views/productHome"),
            },
            {
              path: "Product",
              name: "Product",
              meta: {
                requiresAuth: false,
              },
              component: () => import("./components/Products/product"),
            },
          ],
        },
        {
          path: "services",
          name: "services",
          meta: {
            requiresAuth: false,
          },
          component: () => import("./views/serviceHome"),
        },
        {
          path: "clients",
          name: "clients",
          meta: {
            requiresAuth: false,
          },
          component: () => import("./views/clientHome"),
        },
        {
          path: "contact",
          name: "Contact",
          meta: {
            requiresAuth: false,
          },
          component: () => import("./views/contactHome"),
        },
        {
          path: "quoteRequest",
          name: "QuoteRequest",
          meta: {
            requiresAuth: false,
          },
          component: () => import("./views/quoteRequestHome"),
        },
        {
          path: "About",
          name: "About",
          meta: {
            requiresAuth: false,
          },
          component: () => import("./views/aboutHome"),
        },
        {
          path: "comingsoon",
          name: "comingsoon",
          props: true,
          component: () => import("./components/common/comingsoon"),
          meta: {
            requiresAuth: false,
          },
        },

        // {
        //   path: "Internship",
        //   name: "Internship",
        //   props: true,
        //   component: () => import("./components/Internship/Auth/Login"),
        //   meta: {
        //     requiresAuth: false,
        //   },
        // },

        {
          path: "/Internship",
          props: true,
          component: () => import("./layouts/Authentication"),
          // meta: {
          //   requiresAuth: false,
          // },
          children: [
            {
              path: "login",
              name: "login",
              meta: {
                requiresAuth: false,
              },
              component: () =>
                import("./components/Internship/Auth/adminLogin"),
            },
          ],
        },
        
        {
          path: "InternshipDetails",
          name: "internDetails",
          props: true,
          component: () =>
            import("./components/Internship/Pages/InternshipDetails"),
          meta: {
            requiresAuth: true,
          },
        },

        {
          path: "InternHomePage",
          name: "InternHomePage",
          props: true,
          component: () =>
            import("./components/Internship/Pages/HomePage"),
          meta: {
            requiresAuth: false,
          },
        },
          
        {
          path: "InternshipCandidate",
          name: "InternshipCandidate",
          props: true,
          component: () =>
            import("./components/Internship/Pages/InternshipDetailsNA"),
          meta: {
            requiresAuth: false,
          },
        },

        {
          path: "InternshipRegistration",
          name: "InternshipRegistration",
          props: true,
          component: () =>
            import("./components/Internship/Pages/InternshipRegistration"),
          meta: {
            requiresAuth: false,
          },
        },
      ],
    },

    {
      path: "/servererror",
      name: "servererror",
      props: true,
      component: () => import("./components/common/500"),
      meta: {
        requiresAuth: false,
      },
    },

    {
      path: "*",
      name: "404pagenotfound",
      props: true,
      component: () => import("./components/common/404"),
      meta: {
        requiresAuth: false,
      },
    },
  ],
  scrollBehavior() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
  },
});

router.beforeEach((to, from, next) => {
  //new lines
  localStorage.setItem("PRE", from.path);

  // end  of newely added lines
  if (
    to.matched.some((route) => route.meta.requiresAuth == true) &&
    store.state.isLoggedIn == false
  ) {
    console.log(from);
    next({ name: "login", params: { lastPage: from } });
    return;
  }

  if ((to.name == "login" || to.name== "InternHomePage") && store.state.isLoggedIn == true) {
    next({ name: "internDetails" });
    return;
  }
  next();
});

export default router;
